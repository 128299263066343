import React from 'react'
import { Link } from 'gatsby'
import ProjectCard from './ProjectCard'

const ProjectsList = props => {
  const getImage = project => props.images.find(image => image.node.relativePath === project.node.images[0].src).node.childImageSharp.fluid

  return (
    <ul className="projects-page__list">
      {props.projects.map((project, index) =>
        <li key={index}>
          <ProjectCard key={index} data={project.node} image={getImage(project)}/>
        </li>
      )}
    </ul>
  )
}

export default ProjectsList
