import React from 'react'
import Container from '@components/shared/container'
import ProjectsFilter from '@components/pages/projects/ProjectsFilter'
import ProjectsList from '@components/pages/projects/ProjectsList'

export default class ProjectsContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      projects: props.projects,
      images: props.images,
      filteredProjects: props.projects,
      filteredImages: props.images,
      activeFilter: 'all',
    }
  }

  handleFilter = option => {
    this.state.activeFilter = option
    if (option === 'all') {
      this.setState({ filteredProjects: this.state.projects, filteredImages: this.state.images })
    } else {
      const filteredProjects = this.state.projects.filter(element => element.node.categories.includes(option))
      const imagePaths = filteredProjects.map(project => project.node.images[0].src)
      const filteredImages = this.state.images.filter(image => imagePaths.includes(image.node.relativePath))
      this.setState({ filteredProjects: filteredProjects, filteredImages: filteredImages })
    }
  }

  render () {
    return (
      <Container>
        <div className="projects page-padding">
          <ProjectsFilter
            onFilter={this.handleFilter}
            activeFilter={this.state.activeFilter}
          />
          <ProjectsList
            projects={this.state.filteredProjects}
            images={this.state.filteredImages}
          />
        </div>
      </Container>
    )
  }
}
