import React from 'react'

const ProjectsFilter = props => {
  return (
    <div className="projects-page__filters filters">
      <div className="filters__label">Filtrer par :</div>
      <span className="filters__line" aria-hidden="true"></span>
      <div className={`filters__filter ${ props.activeFilter === 'drupal 8' ? 'filters__filter--active' : '' }`} aria-label="Filtrer avec Drupal 8" onClick={() => { props.onFilter('drupal 8') }}>Drupal 8</div>
      <span className="filters__line" aria-hidden="true"></span>
      <div className={`filters__filter ${ props.activeFilter === 'multilingue' ? 'filters__filter--active' : '' }`} aria-label="Filtrer avec Multilingue" onClick={() => { props.onFilter('multilingue') }}>Multilingue</div>
      <span className="filters__line" aria-hidden="true"></span>
      <div className={`filters__filter ${ props.activeFilter === 'ecommerce' ? 'filters__filter--active' : '' }`} aria-label="Filtrer avec Ecommerce" onClick={() => { props.onFilter('ecommerce') }}>E-commerce</div>
      <span className="filters__line" aria-hidden="true"></span>
      <div className={`filters__filter ${ props.activeFilter === 'responsive' ? 'filters__filter--active' : '' }`} aria-label="Filtrer avec Responsive" onClick={() => { props.onFilter('responsive') }}>Responsive</div>
      <span className="filters__line" aria-hidden="true"></span>
      <div className={`filters__filter ${ props.activeFilter === 'all' ? 'filters__filter--active' : '' }`} aria-label="Afficher tous les projets" onClick={() => { props.onFilter('all') }}>Tous les projets</div>
    </div>
  )
}

export default ProjectsFilter
