import React from 'react'
import Layout from '@components/layout/layout'
import SEO from '@components/shared/seo'
import { graphql } from 'gatsby'
import ProjectsContainer from '@components/pages/projects/ProjectsContainer'
import seoMeta from '@seo-data/projects'

export default class ProjectsPage extends React.Component {
  constructor ({ data }) {
    super()
    this.projects = data.projects.edges
    this.images = data.images.edges
  }
  render () {
    return (
      <Layout>
        <SEO data={seoMeta} />
        <ProjectsContainer
          projects={this.projects}
          images={this.images}
        />
      </Layout>
    )
  }
}

export const query = graphql` 
query {
  projects : allProjectsJson {
    edges {
      node {
        path
        title
        categories
        description
        images {
          src
          alt
        }
      }
    }
  }
  images : allFile(filter: {relativePath: {regex: "/projet/"}}) {
    edges {
      node {
        relativePath
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
