const seoData = {
  "openGraph": {
    "id": "projets",
    "title": "Nos projets",
    "description": "Liste des projets sur lesquels nous avons travaillé.",
  },
  "microdata": {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Nos projets",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "item": "https://www.acolad.fr/",
          "name": "Accueil"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "item": "https://www.acolad.fr/projets",
          "name": "Nos projets"
        }
      ]
    }
  }
}

export default seoData
